import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { Avatar } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { Modal, Box } from '@mui/material';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { FaPencilAlt } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import AdminAddUser from './AdminAddUser';
import AdminChangePasswordUser from './AdminChangePasswordUser';
import AdminRemoveUser from './AdminRemoveUser';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
var CryptoJS = require("crypto-js");

    

const AdminUsers = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();

    var userVar = "test123"
    
    
  const [flagsData, setFlags] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedFlag, setClickedFlag] = useState(false);

  const [openAddFlag, setOpenAddFlag] = React.useState(false);
  const handleOpenAddFlag = () => setOpenAddFlag(true);
  const handleCloseAddFlag = () => setOpenAddFlag(false);

  const [openChangeFlag, setOpenChangeFlag] = React.useState(false);
  const handleOpenChangeFlag = () => setOpenChangeFlag(true);
  const handleCloseChangeFlag = () => setOpenChangeFlag(false);


  const [openRemoveFlag, setOpenRemoveFlag] = React.useState(false);
  const handleOpenRemoveFlag = () => setOpenRemoveFlag(true);
  const handleCloseRemoveFlag = () => setOpenRemoveFlag(false);

  const [addName, setAddName] = useState([]);
  const [addURL, setAddURL] = useState([]);
  
  const [changeURL, setChangeURL] = useState([]);
  const [changeName, setChangeName] = useState([]);

  const [image, setImage] = useState({ preview: '', data: '', name: '' })
  const [status, setStatus] = useState('')

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    background: 'white',
    boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
    minWidth: 400,
    borderRadius: 5
  };



  async function getFlags(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/flagsAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  

   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin
  }



  const thisflags = async () => {
    var isAdminReturn = await adminChecker();
    const data = await getFlags({
     isAdmin: isAdminReturn
    });
    setFlags(data)
    setIsLoading(false)
  }

  let handleSubmitAddFlag = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    const fullURL = `https://piratenzenders.com/assets/flags/${image.name}`;

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ nameAdd: addName, urlAdd: fullURL, isAdmin: isAdminString })
    };
    let formData = new FormData()
    formData.append('file', image.data)
    formData.append('isAdmin', isAdminString)

    const res = await fetch('https://catalina.xseu.net/beheer-pz/image', {
      method: 'POST',
      body: formData,
    })
        .then(response => response.json())

        let res2 = await fetch('https://catalina.xseu.net/beheer-pz/addFlagAdmin', requestOptions)
            .then(response => response.json())
      if (res2.message === "Bijwerken geslaagd") {
        toast.success('Het toevoegen van de vlag is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisflags();
      } else {
        toast.error('Het toevoegen van de vlag is mislukt', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name
    }
    setImage(img)
  }

  let handleSubmitChangeFlag = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    const fullURL = `https://piratenzenders.com/assets/${image.name}`;

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ changeURLInto: changeName, selectedFlag: clickedFlag, isAdmin: isAdminString })
    };
    let formData = new FormData()
    formData.append('file', image.data)
    formData.append('isAdmin', isAdminString)

    const res = await fetch('https://catalina.xseu.net/beheer-pz/image', {
      method: 'POST',
      body: formData,
    })
        .then(response => response.json())

        let res2 = await fetch('https://catalina.xseu.net/beheer-pz/changeFlagAdmin', requestOptions)
            .then(response => response.json())
      if (res2.message === "Bijwerken geslaagd") {
        toast.success('Het wijzigen van het wachtwoord is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisflags();
      } else {
        toast.error('Het wijzigen van het wachtwoord is mislukt', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  let handleSubmitRemoveFlag = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ flagToDelete: clickedFlag, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/removeFlagAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het verwijderen van de vlag is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisflags();
      } else {
        toast.error('Het verwijderen van de vlag is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {

  
      thisflags();
  


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{minWidth: 350, marginLeft: 20, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{minWidth: 350, marginLeft: 20, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }



  return(
    
    <div>
      <Modal
        open={openAddFlag}
        onClose={handleCloseAddFlag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <center>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Vlag toevoegen</Alert>
      
        {image.preview && <img src={image.preview} width='100' height='100' />}
      <hr></hr>
      <form onSubmit={handleSubmitAddFlag}>
        <Typography style={{color: '#428bca'}}><strong>Vlag naam: </strong></Typography><TextField id="add_password" variant="outlined" name="add_name" type="text" style={{width: 200}} onChange={(e) => setAddName(e.target.value)}></TextField>
        <br />
        <input type='file' name='file' onChange={handleFileChange}></input>
        <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
      </form>
      {status && <h4>{status}</h4>}
      
      </center>
    
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseAddFlag}>Sluiten</Button>
        </Box>
        
      </Modal>

      <Modal
        open={openChangeFlag}
        onClose={handleCloseChangeFlag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <center>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Vlag toevoegen</Alert>
      
        {image.preview && <img src={image.preview} width='100' height='100' />}
      <hr></hr>
      <form onSubmit={handleSubmitAddFlag}>
        <Typography style={{color: '#428bca'}}><strong>Vlag naam: </strong></Typography><TextField id="add_password" variant="outlined" name="add_name" type="text" style={{width: 200}} onChange={(e) => setAddName(e.target.value)}></TextField>
        <br />
        <input type='file' name='file' onChange={handleFileChange}></input>
        <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
      </form>
      {status && <h4>{status}</h4>}
      
      </center>
    
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangeFlag}>Sluiten</Button>
        </Box>
        
      </Modal>


      <Modal
        open={openRemoveFlag}
        onClose={handleCloseRemoveFlag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je vlag {clickedFlag} echt verwijderen?</Alert>
        <br />
        <Alert severity='info'>Ververs de pagina om de wijzigingen te zien.</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmitRemoveFlag}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRemoveFlag}>Sluiten</Button>
        </Box>
        
      </Modal>



      <Card style={{minHeight: 200, minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <Link to="/admin/logout" style={{position: 'relative', marginTop: 10, marginLeft: 10, marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
      <br />
      <List>
      <Button onClick={(handleOpenAddFlag)}>
        <FaPlus style={{marginRight: 5, color: 'orange'}} />Vlag toevoegen</Button>
        <Alert style={{marginTop: 10}} severity="success"><strong>Welkom, {username}! Dit zijn de vlaggen.</strong></Alert>
        </List>
      <List>

    
      {flagsData.map((flag, index) => (
      <ListItem divider={index < flagsData.length - 1} key={index}>
      <Button onClick={() => {
          setClickedFlag(flag.id);
          handleOpenChangeFlag();
        }}><FaPencilAlt style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/></Button>
      <Button onClick={() => {
          setClickedFlag(flag.id);
          handleOpenRemoveFlag();
        }}><FaTrashAlt style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/></Button>
      <Avatar src={flag.url} sx={{marginRight: 2, border: "1px solid", borderColor: 'rgb(66, 139, 202)'}}></Avatar>
      <Typography style={{color: 'rgb(66, 139, 202)'}}>{flag.name}</Typography>
      </ListItem>
         ))}
     </List>
      </Card>
      
    </div>
  );
 }
}

export default AdminUsers;


