import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert, List, ListItem } from '@mui/material';
import { Modal, Box, Avatar } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { FaBackspace, FaMicrophoneAltSlash } from 'react-icons/fa';
import { FaPeopleArrows } from 'react-icons/fa';
import { FaToggleOff } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import { FaMicrophone } from 'react-icons/fa';
import { FaMicrophoneSlash } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { FaHandPointRight } from 'react-icons/fa';
import { FaHandPointLeft } from 'react-icons/fa';
import { FaCaretUp } from 'react-icons/fa';
import { FaStickyNote } from 'react-icons/fa';
import { FaFlag } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";

var CryptoJS = require("crypto-js");

export default function Dashboard(props) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    background: 'white',
    boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
    minWidth: 400,
    borderRadius: 5
  };



  const [streamInfo, setStreamInfo] = useState("");
  const [flagsData, setFlagsData] = useState("");
  const [message, setMessage] = useState("");
  const [usersData, setUsers] = useState("");
  const [usersDataByMP, setUsersByMP] = useState("");
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastSeenData, setLastSeen] = useState(true);

  const { mountpoint } = useParams();
  const navigate = useNavigate();
  const usernameString = localStorage.getItem('username');
  const username = JSON.parse(usernameString);

  const [openAssignUser, setOpenAssignUser] = React.useState(false);
  const handleOpenAssignUser = () => setOpenAssignUser(true);
  const handleCloseAssignUser = () => setOpenAssignUser(false);

  const [openAssignFlag, setOpenAssignFlag] = React.useState(false);
  const handleOpenAssignFlag = () => setOpenAssignFlag(true);
  const handleCloseAssignFlag = () => setOpenAssignFlag(false);

  const [openUnassignUser, setOpenUnassignUser] = React.useState(false);
  const handleOpenUnassignUser = () => setOpenUnassignUser(true);
  const handleCloseUnassignUser = () => setOpenUnassignUser(false);


  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleCloseChangePassword = () => setOpenChangePassword(false);


  const [openChangePosition, setOpenChangePosition] = React.useState(false);
  const handleOpenChangePosition = () => setOpenChangePosition(true);
  const handleCloseChangePosition = () => setOpenChangePosition(false);

  const [openChangeStreamTitle, setOpenChangeStreamTitle] = React.useState(false);
  const handleOpenChangeStreamTitle = () => setOpenChangeStreamTitle(true);
  const handleCloseChangeStreamTitle = () => setOpenChangeStreamTitle(false);

  const [assignedUser, setAssignUser] = useState([]);
  const [assignedFlag, setAssignFlag] = useState([]);
  const [newPassword, setNewPassword] = useState([]);
  const [userToUnassign, setUserToUnassign] = useState([]);
  const [newPosition, setNewPosition] = useState([]);
  const [newStreamTitle, setNewStreamTitle] = useState([]);

   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function getUsers(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/usersAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function getLastSeenFunction(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/getLastSeen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function getUsersByMP(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/MPUsersAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function getFlags(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/flagsadmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }



const hideMP = async () => {
  var encryptedIsAdmin = await adminChecker()
  const user = {
    isAdmin: encryptedIsAdmin,
    mountpoint: mountpoint,
    value: "hide"
  };

  axios.post(`https://catalina.xseu.net/beheer-pz/passValueToMPasAdmin`, { user })
    .then(res => {
      console.log(res);
      console.log(res.data);

      if (res.message = "Stream bijgewerkt") {
        toast.success('De stream is verborgen.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

      } else {
        toast.error('Bijwerken mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }



    })
}


const showMP = async () => {
  var encryptedIsAdmin = await adminChecker()
  const user = {
    isAdmin: encryptedIsAdmin,
    mountpoint: mountpoint,
    value: "show"
  };

  axios.post(`https://catalina.xseu.net/beheer-pz/passValueToMPasAdmin`, { user })
    .then(res => {
      console.log(res);
      console.log(res.data);

      if (res.message = "Stream bijgewerkt") {
        toast.success('De stream is verborgen.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

      } else {
        toast.error('Bijwerken mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }


    })
}



const killMP = async () => {
  var encryptedIsAdmin = await adminChecker()
  const user = {
    isAdmin: encryptedIsAdmin,
    mountpoint: mountpoint,
  };

  axios.post(`https://catalina.xseu.net/beheer-pz/killMPasAdmin`, { user })
    .then(res => {
      console.log(res);
      console.log(res.data);


      if (res.data.message == "Stream bijgewerkt") {
        toast.success('De stream is gekilld.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

      } else {
        toast.error('Bijwerken mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }


    })
}




const adminChecker = async () => {
  const adminInfo = await checkIfAdmin({
   username: username
  });
  var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
  var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

  setIsAdmin(originaltext)

  return adminInfo.isAdmin
}

const thisusersbyMP = async () => {
  var isAdminReturn = await adminChecker();
  const data = await getUsersByMP({
   isAdmin: isAdminReturn,
   mountpoint: mountpoint
  });
  setUsersByMP(data)


}

const thisusers = async () => {
  var isAdminReturn = await adminChecker();
  const data = await getUsers({
   isAdmin: isAdminReturn
  });
  setUsers(data)

}

const getLastSeenData = async () => {
  var isAdminReturn = await adminChecker();
  const data = await getLastSeenFunction({
   isAdmin: isAdminReturn,
   mountpoint: mountpoint
  });
  setLastSeen(data)

}


const thisflags = async () => {
  var isAdminReturn = await adminChecker();
  const data = await getFlags({
   isAdmin: isAdminReturn,
  });
  setFlagsData(data)


}

const loadingManager = async () => {
  await setIsLoadingFalse();
}



let LSon = async () => {
  var isAdminString = await adminChecker();

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mountpoint: mountpoint, isAdmin: isAdminString })
  };
  let res = await fetch('https://catalina.xseu.net/beheer-pz/startLiquidSoapAdmin', requestOptions)
      .then(response => response.json())
    if (res.message === "Bijwerken geslaagd") {
      toast.success('Het starten van de mobiele stream is geslaagd.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    } else {
      toast.error('Het starten van de mobiele stream is mislukt.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  } catch (err) {
    console.log(err);
  }
};


let LSoff = async () => {
  var isAdminString = await adminChecker();

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mountpoint: mountpoint, isAdmin: isAdminString })
  };
  let res = await fetch('https://catalina.xseu.net/beheer-pz/killLiquidSoapAdmin', requestOptions)
      .then(response => response.json())
    if (res.message === "Bijwerken geslaagd") {
      toast.success('Het stoppen van de mobiele stream is geslaagd.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    } else {
      toast.error('Het stoppen van de mobiele stream is mislukt', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  } catch (err) {
    console.log(err);
  }
};


let deleteMP = async () => {
  var isAdminString = await adminChecker();

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mountpoint: mountpoint, isAdmin: isAdminString })
  };
  let res = await fetch('https://catalina.xseu.net/beheer-pz/deleteMPAdmin', requestOptions)
      .then(response => response.json())
    if (res.message === "Bijwerken geslaagd") {
      toast.success('Het stoppen verwijderen van de stream is geslaagd.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    } else {
      toast.error('Het verwijderen van de stream is mislukt.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  } catch (err) {
    console.log(err);
  }
};




  let handleSubmit = async (e) => {

    var encrypted = CryptoJS.AES.encrypt(isAdmin, "4CHUNFCHJ99XJ!");
    var encryptedString = encrypted.toString();





      e.preventDefault();
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isAdmin: encryptedString, mountpoint: mountpoint, streamInfo: streamInfo })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/sendInfoToMPAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het bijwerken van de streaminformatie is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else {
        toast.error('Het bijwerken van de streaminformatie is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }

  };


  let handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({mountpoint: mountpoint, newpassword: newPassword, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/changePWMountpointAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Het wijzigen van het wachtwoord is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

      } else {
        toast.error('Het wijzigen van het wachtwoord is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  let handleSubmitAssignFlag = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();
    var handledAssignedFlag = e.target.value
    setAssignFlag(e.target.value)

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assignedFlag: handledAssignedFlag, mountpoint: mountpoint, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/assignFlagAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Het toewijzen van de vlag is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

      } else {
        toast.error('Het toewijzen van de vlag is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  let handleSubmitAssignUser = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();
    var handledAssignedUser = e.target.value
    setAssignUser(e.target.value)

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assignedUser: handledAssignedUser, mountpoint: mountpoint, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/assignUserAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Het toewijzen van de gebruiker is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusersbyMP();

      } else {
        toast.error('Het toewijzen van de gebruiker is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  let handleSubmitUnassignUser = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();
    var handleUserToUnassign = e.target.value
    setUserToUnassign(e.target.value)

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userToUnassign: handleUserToUnassign, mountpoint: mountpoint, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/unassignUserAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Toewijzing van de gebruiker verwijderen is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusersbyMP();
      } else {
        toast.error('Toewijzing van de gebruiker verwijderen is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  let handleSubmitChangePosition = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();
    var handlePosition = e.target.value


    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPosition: newPosition, mountpoint: mountpoint, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/changePositionOfMPAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Wijzigen van de positie is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusersbyMP();
      } else {
        toast.error('Wijzigen van de positie is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleClearClick = () => {
    setStreamInfo("");
  };
  


  let handleSubmitChangeStreamTitle = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();


    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newStreamTitle: newStreamTitle, mountpoint: mountpoint, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/changeStreamTitleOfMPAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success("Wijzigen van de streamtitle is geslaagd.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusersbyMP();
      } else {
        toast.error('Wijzigen van de streamtitle is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  const setIsLoadingFalse = async () => {


    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1500);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    adminChecker();
    thisusers();
    thisusersbyMP();
    getLastSeenData();
    thisflags();
    loadingManager();




  }, []);

  if (isLoading === true) {
    return (
      <div>
      <Card style={{marginLeft: 20, width: 500, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <center>
      <Lottie animationData={Loading} style={{width: 50, height: 50}} />
      <Typography><strong>Laden...</strong></Typography>
      </center>
      </Card>
      </div>
    )
  } else {

  if (isAdmin != "true" ) {
    return (
      <div>
      <Card style={{marginLeft: 20, width: 500, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


  return(
    <div>



<Modal
        open={openChangePosition}
        onClose={handleCloseChangePosition}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Positie wijzigen van {mountpoint}</Alert>
        <center>
        <form onSubmit={handleSubmitChangePosition}>
          <Typography style={{color: '#428bca'}}><strong>Positie </strong></Typography><TextField id="change_password" variant="outlined" name="position" style={{width: 200}} onChange={(e) => setNewPosition(e.target.value)}>Positie</TextField>
          <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
        </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangePosition}>Sluiten</Button>
        </Box>
        
      </Modal>


      <Modal
        open={openChangeStreamTitle}
        onClose={handleCloseChangeStreamTitle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Streamtitle van {mountpoint} wijzigen</Alert>
        <center>
        <form onSubmit={handleSubmitChangeStreamTitle}>
          <Typography style={{color: '#428bca'}}><strong>Nieuwe streamtitle (bijv: Robert - Sappemeer) </strong></Typography><TextField id="change_password" variant="outlined" name="streamTitle" style={{width: 200}} onChange={(e) => setNewStreamTitle(e.target.value)}>Positie</TextField>
          <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
        </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangeStreamTitle}>Sluiten</Button>
        </Box>
        
      </Modal>



<Modal
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Wachtwoord wijzigen van {mountpoint}</Alert>
        <center>
        <form onSubmit={handleSubmitChangePassword}>
          <Typography style={{color: '#428bca'}}><strong>Wachtwoord: </strong></Typography><TextField id="change_password" variant="outlined" name="change_password" type="password" style={{width: 200}} onChange={(e) => setNewPassword(e.target.value)}>Wachtwoord</TextField>
          <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
        </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangePassword}>Sluiten</Button>
        </Box>
        
      </Modal>


        <Modal
        open={openAssignUser}
        onClose={handleCloseAssignUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>{mountpoint} toewijzen aan gebruiker</Alert>
        <center>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Gebruiker</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assignedUser}
          label="Gebruiker"
          onChange={handleSubmitAssignUser}
        >
            {usersData.map((users, index) => (
          <MenuItem value={users.user} key={index}>{users.user}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseAssignUser}>Sluiten</Button>
        </Box>
        
      </Modal>



      <Modal
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Wachtwoord wijzigen van {mountpoint}</Alert>
        <center>
        <form onSubmit={handleSubmitChangePassword}>
          <Typography style={{color: '#428bca'}}><strong>Wachtwoord: </strong></Typography><TextField id="change_password" variant="outlined" name="change_password" type="password" style={{width: 200}} onChange={(e) => setNewPassword(e.target.value)}>Wachtwoord</TextField>
          <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
        </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangePassword}>Sluiten</Button>
        </Box>
        
      </Modal>


        <Modal
        open={openUnassignUser}
        onClose={handleCloseUnassignUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>{mountpoint} verwijderen van gebruiker</Alert>
        <center>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Gebruiker</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userToUnassign}
          label="Gebruiker"
          onChange={handleSubmitUnassignUser}
        >
            {usersDataByMP.map((users, index) => (
          <MenuItem value={users.user} key={index}>{users.user}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseUnassignUser}>Sluiten</Button>
        </Box>
        
      </Modal>

      <Modal
        open={openAssignFlag}
        onClose={handleCloseAssignFlag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Vlag toewijzen aan stream</Alert>
        <center>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Vlag</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assignedFlag}
          label="Vlag"
          onChange={handleSubmitAssignFlag}
        >
            {flagsData.map((flags, index) => (
          <MenuItem value={flags.url} key={index}>
            
            <Avatar src={flags.url} sx={{marginRight: 2, border: "1px solid", borderColor: 'rgb(66, 139, 202)'}}></Avatar>
            {flags.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseAssignFlag}>Sluiten</Button>
        </Box>
        
      </Modal>



      <Card style={{minHeight: 200, minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <br />
      <List>
    <Link to={`/admin/Listeners/${mountpoint}`} style={{color: 'black', textDecoration: 'none'}}><Button><FaPeopleArrows style={{marginRight: 5, color: 'orange'}} />Luisteraars</Button></Link>
    <Button onClick={killMP}><FaToggleOff style={{marginRight: 5, color: 'orange'}} />Kill</Button>
    <Button onClick={showMP}><FaEye style={{marginRight: 5, color: 'orange'}} />Show</Button>
    <Button onClick={hideMP}><FaEyeSlash style={{marginRight: 5, color: 'orange'}} />Hide</Button>
    <Button onClick={LSon}><FaMicrophone style={{marginRight: 5, color: 'orange'}} />LS aan</Button>
    <Button onClick={LSoff}><FaMicrophoneSlash style={{marginRight: 5, color: 'orange'}} />LS uit</Button>
    <Button onClick={deleteMP}><FaTrash style={{marginRight: 5, color: 'orange'}} />Verwijderen</Button>
    <Button onClick={handleOpenChangePassword}><FaKey style={{marginRight: 5, color: 'orange'}} />Wachtwoord wijzigen</Button>
    <Button onClick={handleOpenAssignUser}><FaHandPointRight style={{marginRight: 5, color: 'orange'}} />Gebruiker toewijzen</Button>
    <Button onClick={handleOpenUnassignUser}><FaHandPointLeft style={{marginRight: 5, color: 'orange'}} />Toewijzing verwijderen</Button>
    <Button onClick={handleOpenChangePosition}><FaCaretUp fontSize={20} style={{marginRight: 5, color: 'orange'}} />Positie wijzigen</Button>
    <Button onClick={handleOpenChangeStreamTitle}><FaStickyNote style={{marginRight: 5, color: 'orange'}} />Verander streamtitle</Button>
    <Button onClick={handleOpenAssignFlag}><FaFlag style={{marginRight: 5, color: 'orange'}} />Vlag toewijzen</Button>
    </List>
        <Typography style={{marginTop: 10}}>Je bewerkt nu: <strong>{mountpoint}</strong></Typography>
        <Typography style={{marginTop: 10}}>Laatst online: <strong>{lastSeenData[0]?.last_seen || "Geen data beschikbaar"}</strong></Typography>
        <Typography>Stream info</Typography>
        <form onSubmit={handleSubmit}>
          <TextField id="streaminfo" variant="outlined" value={streamInfo} fullWidth onChange={(e) => setStreamInfo(e.target.value)}>Stream info</TextField>
          <Button onClick={handleClearClick} variant="contained" style={{marginTop: 10, marginRight: 10, marginBottom: 10}}>Opschonen</Button>
          <Alert severity="warning" style={{marginTop: 10 }}>Gebruik _ om een nieuwe regel aan te maken.<p> <strong>Andere karakters werken niet!</strong></p></Alert>
          <Alert severity="info" style={{marginTop: 10}}>
          <Typography style={{marginTop: 0, fontWeight: 'bold'}}>Dit zijn de regels zoals ze op de website te zien zijn:</Typography>
          <Typography style={{color: "rgb(123, 126, 134))"}}>
            {streamInfo.split("_").map((t,key) => {
            return <p key={key}>{t}</p>;
        })}</Typography>
        </Alert>
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        </form>
        <br />
        <div className="message">{message ? <p>{message}</p> : null}</div>
      </Card>
    </div>
  );
 }
}
