import React, { useState, useEffect, Fragment } from 'react';
import { Card, TableCell } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert, List, ListItem } from '@mui/material';
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { FaBackspace } from 'react-icons/fa';
import { FaPeopleArrows } from 'react-icons/fa';

import { Table, TableBody, TableHead, TableRow, TableContainer, Paper, Avatar } from '@mui/material';


import axios from 'axios';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
var CryptoJS = require("crypto-js");

    

const Listeners = (props) => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    const { mountpoint } = useParams();

    var initialMobileValues = {
            "total_listeners_normal": "0",
            "total_listeners_mobile": "0",
            "normal_stream": [
                {
                    "country": "Placeholder",
                    "countrycode": "NL",
                    "city": "Placeholder",
                    "flag": "NL",
                    "IP": "0.0.0.0",
                    "listen_time": "0"
                }
            ],
            "mobile": [
                {
                    "country": "",
                    "countrycode": "",
                    "city": "",
                    "flag": "",
                    "IP": "",
                    "listen_time": ""
                }
            ]
    };


    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    
  const [nowplayingData, setNowPlaying] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotOnline, setNotOnline ] = useState(false);
  const [streamInfo, setStreamInfo] = useState(initialMobileValues);
  const [streamInfoMobile, setStreamInfoMobile] = useState(initialMobileValues);
  const [foundOrNot, setfoundOrNot] = useState(true);
  const [authedMP, setAuthedMP] = useState("");


  async function authMP(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/authMP', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  
  

   async function streamListeners(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/getlisteners', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function streamListenersMobile(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/getlisteners', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

  useEffect(() => {

    const checkMP = async () => {
        const info = await authMP({
         username: username,
         mountpoint: mountpoint
        });
        var decrypted = CryptoJS.AES.decrypt(info.response, "4CHUNFCHJ99XJ!");
        var originaltext = decrypted.toString(CryptoJS.enc.Utf8);
        setAuthedMP(originaltext)
      }


  const getStreamListeners = async () => {
    
    const info = await streamListeners({
     mountpoint: mountpoint,
     username: username,


    });
    setStreamInfo(info)
    getStreamListenersMobile();

    if (info.normal_stream == null) {
        setfoundOrNot(false);
      }
  }

  const getStreamListenersMobile = async () => {
    const info = await streamListenersMobile({
     mountpoint: mountpoint,
     username: username
    });

    if (info.mobile == null) {
        setStreamInfoMobile(initialMobileValues)
      } else {
        setStreamInfoMobile(info)
      }
      setIsLoading(false)
  }

    checkMP();
    getStreamListeners();
    
  


 }, [])


 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


    if (authedMP != "true") {
        return (
          <div>
          <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
          <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
          <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen.</strong></Alert>
          </Card>
          </div>
        )
    }
    


    if (foundOrNot === false) {
      return (
        <div>
        <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
        <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
        <Alert severity="error"><strong>Sorry, {username}! Deze stream is offline.</strong></Alert>
        </Card>
        </div>
      )
    }


  return(
    <div>
      <Card style={{minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <List>
        <Typography style={{marginLeft: 10, color: 'rgb(66, 139, 202)'}}><strong>Aantal luisteraars (niet mobiel): {streamInfo.total_listeners_normal}</strong></Typography>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 50 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell style={{width: 270, color: 'rgb(66, 139, 202)', borderBottom: "1px solid rgb(66, 139, 202)" }}>IP-adres</TableCell>
            <TableCell style={{color: 'rgb(66, 139, 202)', borderBottom: "1px solid rgb(66, 139, 202"}}>Land</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {streamInfo.normal_stream.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{borderBottom: "1px solid rgb(66, 139, 202)" }}>
              {row.IP} ({row.listen_time})
              </TableCell>
              <TableCell style={{borderBottom: "1px solid rgb(66, 139, 202)" }}><Avatar alt="Remy Sharp" style={{width: 20, height: 20}} src={`https://megahit.live/clients/flags/${row.flag}.png`} />{row.country} ({row.city})</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     </List>
     <List>
     <Typography style={{marginLeft: 10, color: 'rgb(66, 139, 202)'}}><strong>Aantal luisteraars (mobiel): {streamInfo.total_listeners_mobile}</strong></Typography>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 130 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell style={{width: 270, color: 'rgb(66, 139, 202)', borderBottom: "1px solid rgb(66, 139, 202)" }}>IP-adres</TableCell>
            <TableCell style={{color: 'rgb(66, 139, 202)', borderBottom: "1px solid rgb(66, 139, 202"}}>Land</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {streamInfoMobile.mobile.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{borderBottom: "1px solid rgb(66, 139, 202)" }}>
                {row.IP} ({row.listen_time})
              </TableCell>
              <TableCell style={{borderBottom: "1px solid rgb(66, 139, 202)" }}><Avatar alt="Remy Sharp" style={{width: 20, height: 20}} src={`https://megahit.live/clients/flags/${row.flag}.png`} />{row.country} ({row.city})</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     </List>
      </Card>
    </div>
  );
 }
}

export default Listeners


