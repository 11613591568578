import { useState } from 'react';
var CryptoJS = require("crypto-js");

export default function useToken() {
  const getToken = () => {

    if (localStorage.getItem("username") != null) {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    var decrypted = CryptoJS.AES.decrypt(userToken?.token, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);
    } 

    return originaltext
    
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));

    var decrypted = CryptoJS.AES.decrypt(userToken?.token, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);
    setToken(originaltext);
  };

  return {
    setToken: saveToken,
    token
  }
}