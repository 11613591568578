import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaListAlt, FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import Badge from '@mui/material/Badge';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";

    

const Dashboard = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    

  const [mountpointData, setMountpoints] = useState([]);
  const [openRequestsData, setOpenRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/mountpoints', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function loginRequestUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/countOpenRequests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  


   const thisrequestcounter = async () => {
    const token = await loginRequestUser({
     username: username
    });
    setOpenRequests(token)
    setIsLoading(false)
  }


  useEffect(() => {

    const thistoken = async () => {
    const token = await loginUser({
     username: username
    });

    setMountpoints(token)

    setIsLoading(false);

  }

    thisrequestcounter();
    thistoken();
  


 }, [])
 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
}

if (mountpointData.error == "No mountpoints found") {
  return(
    <div>
      <Card style={{ minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Link to="/logout" style={{position: 'relative', float: 'left', marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>  
        <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
        <br />
        <br />
        <Alert severity="success"><strong>Welkom, {username}! Kies hier je mountpoint</strong></Alert>
        <br />
        <Alert severity="error">Er zijn geen mountpoints aan je account gekoppeld!</Alert>
      </Card>
    </div>
  );
} else {

  return(
    <div>
      <Card style={{minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Link to="/logout" style={{position: 'relative', float: 'left', marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
      <Link to="/StreamRequests" style={{position: 'relative', float: 'left', marginBottom: 10, marginLeft: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}> 
      
      <Button>
      <Badge badgeContent={openRequestsData[0]?.empty_taken_by_count} color="primary">
        <FaListAlt style={{ marginRight: 5, color: 'orange' }} />
      </Badge>
      <Typography style={{marginLeft: 15}}>Streamverzoeken</Typography>
    </Button>

      </Link>
      <br />
      <br />
        <Alert severity="success"><strong>Welkom, {username}! Kies hier je mountpoint</strong></Alert>
      <List>
      {mountpointData.map((mp, index) => (
      <ListItem divider={index < mountpointData.length - 1} key={index}>
      <Link style={{color: '#428bca', textDecoration: 'none'}} to={`/Editor${mp.MP}`}><FaPencilAlt style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/>{mp.MP.replace("/", "")}</Link>
      </ListItem>
         ))}
     </List>
      </Card>
    </div>
  );
 }
}

export default Dashboard;


