import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Modal, Box, Avatar } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
var CryptoJS = require("crypto-js");

    

const AdminCreateMountpoint = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    
  const [usersData, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [addMountpoint, setAdddMountpoint] = useState([]);
  const [addPassword, setAddPassword] = useState([]);
  const [addStreamTitle, setAdddStreamtitle] = useState([]);
  const [addImageURL, setAddImageURL] = useState([]);
  const [addMobileStreamDesc, setMobileStreamDesc] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [flagsData, setFlagsData] = useState("");
  const [assignedFlag, setAssignFlag] = useState([]);


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin
  }
  

  async function getFlags(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/flagsadmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }



  const thisflags = async () => {
    var isAdminReturn = await adminChecker();
    const data = await getFlags({
     isAdmin: isAdminReturn,
    });
    setFlagsData(data)
  
  
  }

  const setAssignFlagFunction = async (e) => {
    e.preventDefault();
    var handledAssignedFlag = e.target.value
    setAssignFlag(e.target.value)
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mountpoint: addMountpoint, password: addPassword, streamtitle: addStreamTitle, imageURL: assignedFlag, mobilestreamdesc: addMobileStreamDesc, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/createMountpointAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het toevoegen van de stream is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          
      } else {
        toast.error('Het toevoegen van de stream is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {

adminChecker();
thisflags();

const timer = setTimeout(() => {
  setIsLoading(false)
}, 1000);
return () => clearTimeout(timer);



 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{ padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


  return(
    <div>
              <Card style={{minHeight: 200, minWidth: '33', padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)", marginBottom: 20 }}>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Mountpoint toevoegen</Alert>
        <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
        <center>
      <form onSubmit={handleSubmit}>
          <Typography style={{color: '#428bca'}}><strong>Mountpont: </strong></Typography><TextField id="add_username" variant="outlined" name="add_username" style={{width: 200}} onChange={(e) => setAdddMountpoint(e.target.value)}>Gebruikersnaam</TextField>
          <Typography style={{color: '#428bca'}}><strong>Wachtwoord: </strong></Typography><TextField id="add_password" variant="outlined" name="add_password" type="password" style={{width: 200}} onChange={(e) => setAddPassword(e.target.value)}>Wachtwoord</TextField>
          <Typography style={{color: '#428bca'}}><strong>Stream titel (eg: Robert-Sappemeer): </strong></Typography><TextField id="add_username" variant="outlined" name="add_username" style={{width: 200}} onChange={(e) => setAdddStreamtitle(e.target.value)}>Gebruikersnaam</TextField>
          <Typography style={{color: '#428bca'}}><strong>Vlag: </strong></Typography>

          <FormControl style={{minWidth: 200}}> 
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assignedFlag}
          label="Vlag"
          onChange={setAssignFlagFunction}
        >
            {flagsData.map((flags, index) => (
          <MenuItem value={flags.url} key={index}>
            
            <Avatar src={flags.url} sx={{marginRight: 2, border: "1px solid", borderColor: 'rgb(66, 139, 202)'}}></Avatar>
            {flags.name}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
          <Typography style={{color: '#428bca'}}><strong>Tekst mobiele stream: </strong></Typography><TextField id="add_password" variant="outlined" name="add_password" style={{width: 200}} onChange={(e) => setMobileStreamDesc(e.target.value)}>Wachtwoord</TextField>
          <br />
          <br />
          <Alert severity='warning' style={{marginTop: 10}}>Vergeet niet om een gebruiker toe te wijzen aan de mountpoint!</Alert>
        <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
        </center>
        </Card>
        </div>


  );
 }
}

export default AdminCreateMountpoint;


