import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import AdminCreateMountpoint from './components/Dashboard/AdminCreateMountpoint';
import Editor from './components/Dashboard/Editor';
import Listeners from './components/Dashboard/Listeners';
import ListenersAdmin from './components/Dashboard/ListenersAdmin';
import EditorAdmin from './components/Dashboard/EditorAdmin';
import Login from './components/Login/Login';
import LogoutAdmin from './components/Login/LogoutAdmin';
import StreamRequests from './components/Dashboard/StreamRequests';
import AdminUsers from './components/Dashboard/AdminUsers';
import AdminAddUser from './components/Dashboard/AdminAddUser';
import AdminRelays from './components/Dashboard/AdminRelays';
import AdminCreateRelay from './components/Dashboard/AdminCreateRelay';
import AdminFlags from './components/Dashboard/AdminFlags';
import AdminRequests from './components/Dashboard/StreamRequestsAdmin';
import { Grid } from '@mui/material';
import useToken from './useToken';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';


var CryptoJS = require("crypto-js");

function setToken(userToken) {
  localStorage.setItem('token', JSON.stringify(userToken));
}

function App() {

  
  const { token, setToken } = useToken();
  const [isLoggedIn, setLogin] = useState();
  const [change, setChange] = useState(false);

  const style = {
    display: 'flex',  justifyContent:'center', alignItems:'center', padding: '10px', maxWidth: '736px'
  };

  

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  var storedtokenDate = dayjs(localStorage.getItem("tokenDate")) 
  var nowDayjsPrev = dayjs()
  var nowDayjs = nowDayjsPrev.add(2, 'hours')
  var difference = nowDayjs.diff(storedtokenDate, 'seconds')

  const Logout = () => {
    localStorage.clear();
    window.location.href="/";
  }

 

  useEffect(() => {
    if (localStorage.getItem("tokenrequestuuid") !== null) {
      setLogin(true);
    }
  }, [change]);


     if (difference > 86400 ) {
       Logout();
      return <Login setToken={setToken} />
    }
 
  var storedtokenDate = dayjs(localStorage.getItem("tokenDate")) 
  var nowDayjsPrev = dayjs()
  var nowDayjs = nowDayjsPrev.add(2, 'hours')
  var difference = nowDayjs.diff(storedtokenDate, 'seconds')


     if (difference > 3600 ) {
       Logout();
      return <Login setToken={setToken} />
    }





  const tokenrequestuuid = localStorage.getItem("tokenrequestuuid")
  const tokenrequestuuidString = JSON.parse(tokenrequestuuid);

  

  if (tokenrequestuuidString != token || isLoggedIn != true) {
    return <Login setToken={setToken} />
  } else {



  return (
    <Router>

    <h1 style={{marginLeft: 25, color: "#428bca", display: 'flex',  justifyContent:'center', alignItems:'center'}}>Piratenzenders.com Beheer</h1>
   <div className="wrapper" style={{padding: 15, color: "#428bca", display: 'flex',  justifyContent:'center', alignItems:'center', maxWidth: '736px', margin: '0 auto'}}>


      <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />

      <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route path="/Editor/:mountpoint" element={<Editor />}/>
          <Route path="/Editor/" element={<Editor />}/>
          <Route path="/StreamRequests/" element={<StreamRequests />}/>
          <Route path="/Listeners/:mountpoint" element={<Listeners />} />
          <Route exact path="/Listeners" element={<Listeners />} />
          <Route exact path="/admin" element={<AdminDashboard />} />
          <Route exact path="/admin/CreateMountpoint" element={<AdminCreateMountpoint />} />
          <Route path="/admin/Editor/:mountpoint" element={<EditorAdmin />}/>
          <Route path="/admin/Listeners/:mountpoint" element={<ListenersAdmin />} />
          <Route path="/admin/Listeners" element={<ListenersAdmin />} />
          <Route path="/admin/Users" element={<AdminUsers />} />
          <Route path="/admin/Users/add" element={<AdminAddUser />} />
          <Route path="/admin/Relays" element={<AdminRelays />} />
          <Route path="/admin/CreateRelay" element={<AdminCreateRelay />} />
          <Route path="/admin/Flags" element={<AdminFlags />} />
          <Route path="/admin/StreamRequests" element={<AdminRequests />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/admin/logout" element={<LogoutAdmin />} />
        </Routes>

    </div>
    </Router>
  );
}
}

export default App;