import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { Modal, Box } from '@mui/material';
import { FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import AdminAddUser from './AdminAddUser';
import AdminChangePasswordUser from './AdminChangePasswordUser';
import AdminRemoveUser from './AdminRemoveUser';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
var CryptoJS = require("crypto-js");

    

const AdminUsers = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();

    var userVar = "test123"
    
    
  const [usersData, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedUser, setClickedUser] = useState(false);

  const [openAddUser, setOpenAddUser] = React.useState(false);
  const handleOpenAddUser = () => setOpenAddUser(true);
  const handleCloseAddUser = () => setOpenAddUser(false);

  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleCloseChangePassword = () => setOpenChangePassword(false);


  const [openRemoveUser, setOpenRemoveUser] = React.useState(false);
  const handleOpenRemoveUser = () => setOpenRemoveUser(true);
  const handleCloseRemoveUser = () => setOpenRemoveUser(false);

  const [addUsername, setAddUsername] = useState([]);
  const [addPassword, setAddPassword] = useState([]);
  
  const [changeUsername, setChangeUsername] = useState([]);
  const [changePassword, setChangePassword] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    background: 'white',
    boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
    minWidth: 400,
    borderRadius: 5
  };



  async function getUsers(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/usersAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  

   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin
  }



  const thisusers = async () => {
    var isAdminReturn = await adminChecker();
    const data = await getUsers({
     isAdmin: isAdminReturn
    });
    setUsers(data)
    setIsLoading(false)
  }

   let handleSubmitAddUser = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameAdd: addUsername, passwordAdd: addPassword, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/addUserAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het toevoegen van de gebruiker is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusers();
      } else {
        toast.error('Het toevoegen van de gebruiker is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  let handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameChangePassword: clickedUser, changePasswordInto: changePassword, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/changePasswordAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het wijzigen van het wachtwoord is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusers();
      } else {
        toast.error('Het wijzigen van het wachtwoord is mislukt', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  let handleSubmitRemoveUser = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameToDelete: clickedUser, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/removeUserAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het verwijderen van de gebruiker is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          thisusers();
      } else {
        toast.error('Het verwijderen van de gebruiker is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {

  
      thisusers();
  


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


  return(
    
    <div>
      <Modal
        open={openAddUser}
        onClose={handleCloseAddUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <center>
        <Alert severity='info' style={{marginBottom: 20, fontWeight: 'bold'}}>Gebruiker toevoegen</Alert>
      <form onSubmit={handleSubmitAddUser}>
          <Typography style={{color: '#428bca'}}><strong>Gebruikersnaam: </strong></Typography><TextField id="add_username" variant="outlined" name="add_username" style={{width: 200}} onChange={(e) => setAddUsername(e.target.value)}>Gebruikersnaam</TextField>
          <Typography style={{color: '#428bca'}}><strong>Wachtwoord: </strong></Typography><TextField id="add_password" variant="outlined" name="add_password" type="password" style={{width: 200}} onChange={(e) => setAddPassword(e.target.value)}>Wachtwoord</TextField>
          <br />
          <br />
          <Alert severity='warning' style={{marginTop: 10}}>Ververs de pagina om de wijzigingen te zien.</Alert>
        <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
      </center>
    
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseAddUser}>Sluiten</Button>
        </Box>
        
      </Modal>

      <Modal
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='info' style={{marginBottom: 20}}>Wachtwoord wijzigen van gebruiker {clickedUser}</Alert>
      <form onSubmit={handleSubmitChangePassword}>
          <Typography style={{color: '#428bca'}}><strong>Wachtwoord: </strong></Typography><TextField id="change_password" variant="outlined" name="change_password" type="password" style={{width: 200}} onChange={(e) => setChangePassword(e.target.value)}>Wachtwoord</TextField>
          <br />
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        
        </form>
      </center>
       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseChangePassword}>Sluiten</Button>
        </Box>
        
      </Modal>


      <Modal
        open={openRemoveUser}
        onClose={handleCloseRemoveUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je {clickedUser} echt verwijderen?</Alert>
        <br />
        <Alert severity='info'>Ververs de pagina om de wijzigingen te zien.</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmitRemoveUser}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRemoveUser}>Sluiten</Button>
        </Box>
        
      </Modal>


      <Card style={{minHeight: 200, minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <Link to="/admin/logout" style={{position: 'relative', marginTop: 10, marginLeft: 10, marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
      <br />
      <List>
      <Button onClick={handleOpenAddUser}><FaHandshake style={{marginRight: 5, color: 'orange'}} />Gebruiker toevoegen</Button>
        <Alert style={{marginTop: 10}} severity="success"><strong>Welkom, {username}! Dit zijn de gebruikers.</strong></Alert>
        </List>
      <List>
      {usersData.map((users, index) => (
      <ListItem divider={index < usersData.length - 1} key={index}>
      <Button onClick={() => {
          setClickedUser(users.user);
          handleOpenChangePassword();
        }}><FaKey style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/></Button>
      <Button onClick={() => {
          setClickedUser(users.user);
          handleOpenRemoveUser();
        }}><FaTrashAlt style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/></Button>
      <Typography style={{color: 'rgb(66, 139, 202)'}}>{users.user}</Typography>
      </ListItem>
         ))}
     </List>
      </Card>
      
    </div>
  );
 }
}

export default AdminUsers;


