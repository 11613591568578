import React, { useState } from 'react';
import "./styles.css";
import PropTypes from 'prop-types';
import Lottie from "lottie-react";
import Loading from "./97930-loading.json";
import dayjs from 'dayjs';
import Logo from './logo.png';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

var CryptoJS = require("crypto-js");


async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

   async function authUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/authUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);


    if (localStorage.getItem("tokenrequestuuid") === null) {
      var uuid = require("uuid");
      var tokenrequestuuid = uuid.v4();
      var nowDayjsPrev = dayjs()
      var nowDayjs = nowDayjsPrev.add(2, 'hours')

      localStorage.setItem('tokenrequestuuid', JSON.stringify(tokenrequestuuid));
      localStorage.setItem('tokenDate', nowDayjs);
    } else {
  

    const tokenrequestuuidString = localStorage.getItem('tokenrequestuuid');
    const tokenrequestuuid = JSON.parse(tokenrequestuuidString);



    const handleSubmit = async (event) => {
      //Prevent page reload
      event.preventDefault();
  
      var { uname, pass } = document.forms[0];
  
          setIsSubmitted(true);
          var username = uname.value
          var password = pass.value
          const token = await loginUser({
            username,
            password,
            tokenrequestuuid
          });

          if (token.error != "Invalid credentials") {
          setToken(token);
          localStorage.setItem('username', JSON.stringify(uname.value));

      } else {
        // Username not found
        setErrorMessages({ message: "Ongeldige inloggegevens" });
        setHasFailed(true);
        
      }
    };
  
    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
      name === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
      );
  
    // JSX code for login form
    const renderForm = (
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label><strong>Gebruikersnaam</strong></label>
            <input type="text" name="uname" required />
            {renderErrorMessage("uname")}
          </div>
          <div className="input-container">
            <label><strong>Wachtwoord</strong></label>
            <input type="password" name="pass" required />
            {renderErrorMessage("pass")}
          </div>
          <div className="button-container">
            <input type="submit" />
          </div>
        </form>
      </div>
    );
  
    return (
      <div className="app">
        <div className="login-form">
          <center>
          <img src={Logo} width="150" />
          </center>
          <div className="title">Inloggen</div>
          {isSubmitted ? <></>: renderForm}
          {hasFailed ? <div style={{color: 'red', fontWeight: 'bold'}}>Ongeldige inloggegevens.  <div style={{color: 'black'}}>{renderForm}</div></div> : <div></div>}
        </div>
      </div>
    );

}
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }