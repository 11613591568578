import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaCheckCircle, FaHandPointRight, FaListAlt, FaMicrophoneAlt, FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from '@mui/material';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  background: 'white',
  boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
  minWidth: 400,
  borderRadius: 5
};

    

const StreamRequests = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    

  const [requestsData, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openRemoveRequest, setOpenRemoveRequest] = React.useState(false);
  const handleOpenRequest = () => setOpenRemoveRequest(true);
  const handleCloseRemoveRequest = () => setOpenRemoveRequest(false);
  const [clickedRequest, setClickedRequest] = useState([]);


  let handleTakeRequest = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestToTake: clickedRequest, username: username})
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/takeRequest', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Je hebt dit verzoek aangenomen', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          handleCloseRemoveRequest();
          thisrequests();
      } else {
        toast.error('Verzoek aannemen mislukt', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  const thisrequests = async () => {
    const token = await loginUser({
     username: username,
    });
    setRequests(token)
    setIsLoading(false)
  }


  async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/streamrequestsUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  

  useEffect(() => {

    const thistoken = async () => {
    const token = await loginUser({
     username: username
    });

    setRequests(token)
    setIsLoading(false)

  }

    thistoken();
  


 }, [])
 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
}

if (requestsData.error == "No streamrequests found") {
  return(
    <div>
      <Card style={{ minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Link to="/logout" style={{position: 'relative', float: 'left', marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>  
        <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
        <Button onClick={() => navigate(-1)} style={{marginTop: -5, fontWeight: 'bold'}}><FaBackspace style={{width: 16, height: 16, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
        <br />
        <br />
        <Alert severity="error">Er zijn geen aanvragen gevonden!</Alert>
      </Card>
    </div>
  );
} else {

  return(

    
    <div>


<Modal
        open={openRemoveRequest}
        onClose={handleCloseRemoveRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je dit verzoek aannemen?</Alert>
        <br />
        <Button variant="contained" onClick={handleTakeRequest}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRemoveRequest}>Sluiten</Button>
        </Box>
        
      </Modal>


      <Card style={{minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <Link to="/logout" style={{position: 'relative', float: 'left', textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
      <Button onClick={() => navigate(-1)} style={{marginTop: -5, fontWeight: 'bold'}}><FaBackspace style={{width: 16, height: 16, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <br />
      <List>
      {requestsData.map((request, index) => (
      <ListItem divider={index < requestsData.length - 1} key={index}>
             <Alert severity="info" key={index}>



<Typography style={{fontWeight: 'bold'}}>
Station: {request.stationName}</Typography>
<Typography style={{ }}>Frequentie: {request.frequency}</Typography>
<Typography>Regio: {request.region}</Typography>
<Typography>Datum: {request.date}</Typography>
<Typography>{request.taken_by ? `(Aangenomen door ${request.taken_by})` : 'Nog niet aangenomen'} </Typography>

<br />
<Button onClick={() => {
    setClickedRequest(request.stationName);
    handleOpenRequest(request.stationName);
  }}><FaCheckCircle style={{width: 18, height: 18, color: 'green', marginLeft: -40}}/></Button>

</Alert>
      </ListItem>
         ))}
     </List>
      </Card>
    </div>




  );
 }

}

export default StreamRequests;


