import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert, List, ListItem } from '@mui/material';
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { FaBackspace } from 'react-icons/fa';
import { FaPeopleArrows } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { FaPlay } from 'react-icons/fa';
import { FaStop } from 'react-icons/fa';
import axios from 'axios';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";


var CryptoJS = require("crypto-js");

export default function Dashboard(props) {
  const [streamInfo, setStreamInfo] = useState("");
  const [message, setMessage] = useState("");
  const [testresponse, setTestReponse] = useState("");
  const [authedMP, setAuthedMP] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasElevatedLiquidSoapRightsForMountpoint, SetHasElevatedLiquidSoapRightsForMountpoint] = useState([])
  const [encryptedElevatedLiquidSoapRightsForMountpoint, SetEncryptedHasElevatedLiquidSoapRightsForMountpoint] = useState([])

  const { mountpoint } = useParams();
  const navigate = useNavigate();
  const usernameString = localStorage.getItem('username');
  const username = JSON.parse(usernameString);


  async function authMP(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/authMP', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  

const postData = async () => {
  const user = {
    name: "Dennis"
  };

  axios.post(`https://catalina.xseu.net/beheer-pz/test`, { user })
    .then(res => {
      console.log(res);
      console.log(res.data);
    })
}



let startRelay = async () => {

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: mountpoint, isAdmin: "false", hasElevatedLiquidSoapRightsForMountpoint: encryptedElevatedLiquidSoapRightsForMountpoint })
  };
  let res = await fetch('https://catalina.xseu.net/beheer-pz/startRelayAdmin', requestOptions)
      .then(response => response.json())
    if (res.message === "Bijwerken geslaagd") {
      toast.success('Het starten van de relay is geslaagd.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    } else {
      toast.error('Het starten van de relay is mislukt.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  } catch (err) {
    console.log(err);
  }
};

let stopRelay = async () => {

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: mountpoint, isAdmin: "false", hasElevatedLiquidSoapRightsForMountpoint: encryptedElevatedLiquidSoapRightsForMountpoint })
  };
  let res = await fetch('https://catalina.xseu.net/beheer-pz/stopRelayAdmin', requestOptions)
      .then(response => response.json())
    if (res.message === "Bijwerken geslaagd") {
      toast.success('Het stoppen van de relay is geslaagd.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    } else {
      toast.error('Het stoppen van de relay is mislukt.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  } catch (err) {
    console.log(err);
  }
};



  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, mountpoint: mountpoint, streamInfo: streamInfo })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/sendInfoToMP', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het bijwerken van de streaminformatie is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else {
        toast.error('Het bijwerken van de streaminformatie is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  

  useEffect(() => {
    postData()

    const checkMP = async () => {
      const info = await authMP({
       username: username,
       mountpoint: mountpoint
      });
      var decrypted = CryptoJS.AES.decrypt(info.response, "4CHUNFCHJ99XJ!");
      var originaltext = decrypted.toString(CryptoJS.enc.Utf8);
      setAuthedMP(originaltext)
      var decryptedLiquidSoapRights = CryptoJS.AES.decrypt(info.hasElevatedLiquidSoapRightsForMountpoint ? info.hasElevatedLiquidSoapRightsForMountpoint : "dummy" , "4CHUNFCHJ99XJ!");
      var originaltextLiquidSoapRights = decryptedLiquidSoapRights.toString(CryptoJS.enc.Utf8);
      SetEncryptedHasElevatedLiquidSoapRightsForMountpoint(info.hasElevatedLiquidSoapRightsForMountpoint?.toString(CryptoJS.enc.Utf8));
      SetHasElevatedLiquidSoapRightsForMountpoint(originaltextLiquidSoapRights);
      setIsLoading(false)
    }
  
      checkMP();


  }, []);

  if (isLoading == true) {
    return (
      <div>
      <Card style={{marginLeft: 20, width: 500, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
      <center>
      <Lottie animationData={Loading} style={{width: 50, height: 50}} />
      <Typography><strong>Laden...</strong></Typography>
      </center>
      </Card>
      </div>
    )
  } else {


  if (authedMP != "true") {
    return (
      <div>
      <Card style={{marginLeft: 20, width: 500, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen.</strong></Alert>
      </Card>
      </div>
    )
  }

  return(
    <div>
      <Card style={{marginLeft: 20, width: 500, minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
        <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
        <List>
        {hasElevatedLiquidSoapRightsForMountpoint === "true" &&
        <>
    <Button onClick={startRelay}><FaPlay style={{marginRight: 5, color: 'orange'}} />Relay aan</Button>
    <Button onClick={stopRelay}><FaStop style={{marginRight: 5, color: 'orange'}} />Relay uit</Button>
    </>

  }
          <Link to={`/Listeners/${mountpoint}`} style={{color: 'black', textDecoration: 'none', marginLeft: '5px'}}><Button><FaPeopleArrows style={{marginRight: 5, color: 'orange'}} />Luisteraars</Button></Link>
        </List>
        <Typography>Je bewerkt nu: <strong>{mountpoint}</strong></Typography>
        <Typography>Stream info</Typography>
        <form onSubmit={handleSubmit}>
          <TextField id="streaminfo" variant="outlined" style={{width: 500}} onChange={(e) => setStreamInfo(e.target.value)}>Stream info</TextField>
          <Alert severity="warning" style={{marginTop: 10 }}>Gebruik _ om een nieuwe regel aan te maken.<p> <strong>Andere karakters werken niet!</strong></p></Alert>
          <Alert severity="info" style={{marginTop: 10}}>
          <Typography style={{marginTop: 0, fontWeight: 'bold'}}>Dit zijn de regels zoals ze op de website te zien zijn:</Typography>
          <Typography style={{color: "rgb(123, 126, 134))"}}>
            {streamInfo.split("_").map((t,key) => {
            return <p key={key}>{t}</p>;
        })}</Typography>
        </Alert>
        <Button variant="contained" type="submit" style={{marginTop: 10}}>Verzenden</Button>
        </form>
        <div className="message">{message ? <p>{message}</p> : null}</div>
      </Card>
    </div>
  );
 }
}