import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaPencilAlt, FaWeight } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaPlay } from 'react-icons/fa';
import { FaStop } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { FaCheckCircle, FaHandPointRight, FaListAlt, FaMicrophoneAlt } from 'react-icons/fa';
import { Modal, Box } from '@mui/material';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
import { ToastContainer, toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

    

const AdminRequests = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    
  const [relayData, setRelays] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [requestsData, setRequests] = useState([]);

  const [openTakeRequest, setOpenTakeRequest] = React.useState(false);
  const handleOpenRequest = () => setOpenTakeRequest(true);
  const handleCloseRequest = () => setOpenTakeRequest(false);
  const [clickedRequest, setClickedRequest] = useState([]);

  const [openRemoveRequest, setOpenRemoveRequest] = React.useState(false);
  const handleOpenRemoveRequest = () => setOpenRemoveRequest(true);
  const handleCloseRemoveRequest = () => setOpenRemoveRequest(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    background: 'white',
    boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
    minWidth: 400,
    borderRadius: 5
  };


  async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/streamrequestsUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin;
  }

  const thisrequests = async () => {
    var isAdminString = await adminChecker();
    const token = await loginUser({
     username: username,
     isAdmin: isAdminString
    });
    setRequests(token)
    setIsLoading(false)
  }

  let handleTakeRequest = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestToTake: clickedRequest, username: username})
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/takeRequest', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Je hebt dit verzoek aangenomen', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          handleCloseRequest();
          thisrequests();
      } else {
        toast.error('Verzoek aannemen mislukt', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

   let handleSubmitRemoveRequest = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestToDelete: clickedRequest, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/removeStreamRequestAsAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het verwijderen van de aanvraag is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          handleCloseRemoveRequest();
          thisrequests();
      } else {
        toast.error('Het verwijderen van de aanvraag is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  

  useEffect(() => {
  

    adminChecker();
      thisrequests();
  


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{ padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }
  if (requestsData.error == "No streamrequests found") {
    return(
      <div>
        <Card style={{ minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
        <Link to="/logout" style={{position: 'relative', float: 'left', marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>  
          <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
          <Button onClick={() => navigate(-1)} style={{marginTop: -5, fontWeight: 'bold'}}><FaBackspace style={{width: 16, height: 16, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
          <br />
          <br />
          <Alert severity="error">Er zijn geen aanvragen gevonden!</Alert>
        </Card>
      </div>
    );
  } else {
  
    return(
  
      
      <div>
  
  
  <Modal
          open={openTakeRequest}
          onClose={handleCloseRequest}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
  
          <center>
          <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je dit verzoek aannemen?</Alert>
          <br />
          <Button variant="contained" onClick={handleTakeRequest}>Bevestigen</Button>
        </center>
  
         <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRequest}>Sluiten</Button>
          </Box>
          
        </Modal>




        <Modal
        open={openRemoveRequest}
        onClose={handleCloseRemoveRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je streamverzoek met ID {clickedRequest} echt verwijderen?</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmitRemoveRequest}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRemoveRequest}>Sluiten</Button>
        </Box>
        
      </Modal>
  
  
        <Card style={{minHeight: 200, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
        <Link to="/logout" style={{position: 'relative', float: 'left', textDecoration: 'none', color: 'rgb(66, 139, 202)', fontWeight: 'bold'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
        <Button onClick={() => navigate(-1)} style={{marginTop: -5, fontWeight: 'bold'}}><FaBackspace style={{width: 16, height: 16, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
        <br />
        <List>
        {requestsData.map((request, index) => (
        <ListItem divider={index < requestsData.length - 1} key={index}>
        
        <Alert severity="info" key={index}>

        <Typography style={{fontWeight: 'bold'}}>
    

        
        Station: {request.stationName}</Typography>
        <Typography style={{ }}>Frequentie: {request.frequency}</Typography>
        <Typography>Regio: {request.region}</Typography>
        <Typography>Datum: {request.date}</Typography>
        <Typography>{request.taken_by ? `(Aangenomen door ${request.taken_by})` : 'Nog niet aangenomen'} </Typography>



<Box style={{marginTop: 20, marginLeft: -10}}>
          <Button onClick={() => {
            setClickedRequest(request.stationName);
            handleOpenRequest(request.stationName);
          }}><FaCheckCircle style={{width: 18, height: 18, color: 'green'}}/></Button>

        <Button onClick={() => {
          setClickedRequest(request.ID);
          handleOpenRemoveRequest(request.ID);
        }}><FaTrash style={{width: 18, height: 18, color: 'orange'}}/></Button>
        </Box>

    
        </Alert>
        </ListItem>
           ))}
       </List>
        </Card>
      </div>
  
  
  
  
    );
   }
  
  }
}

export default AdminRequests;


