import React, { useState, useEffect } from 'react';
import "./styles.css";
import PropTypes from 'prop-types';
import Lottie from "lottie-react";
import Loading from "./97930-loading.json";
import  { Navigate } from 'react-router-dom'


export default function Logout() {

    const Logout = () => {
        localStorage.clear();
        window.location.href="/admin";
    }

    useEffect(() => {

      Logout();
      
    
    
     }, [])
    
  
     return 

}
