import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';

import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaLink} from 'react-icons/fa';
import { FaFlag } from 'react-icons/fa';
import { FaListAlt } from 'react-icons/fa';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
var CryptoJS = require("crypto-js");

    

const Dashboard = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    
  const [mountpointData, setMountpoints] = useState([]);
  const [openRequestsData, setOpenRequests] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/mountpointsAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }



  async function loginRequestUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/countOpenRequests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin;
  }


   async function passCreateMountpoint(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/createMountpointAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

  

  useEffect(() => {





    const thistoken = async () => {
      const token = await loginUser({
       username: username
      });
      setMountpoints(token)
      setIsLoading(false)
    }
  

    const thisrequestcounter = async () => {
      const token = await loginRequestUser({
       username: username
      });
      setOpenRequests(token)
      setIsLoading(false)
    }

    adminChecker();
    thisrequestcounter();
      thistoken();
  


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


      // Group mount points by user
      const mpPerUser = {};
      mountpointData.forEach((mp) => {
        if (mpPerUser[mp.user]) {
          mpPerUser[mp.user].push(mp.MP);
        } else {
          mpPerUser[mp.user] = [mp.MP];
        }
      });

      return (
        <div>
          <Card
            style={{
              minHeight: 200,
              minWidth: 350, 
              padding: 10,
              boxShadow: '0 1px 22px 4px rgba(0,0,0,.07)',
              marginBottom: 20,
            }}
          >
            <Button onClick={() => navigate(-1)}>
              <FaBackspace style={{ width: 30, height: 30, marginRight: 10, color: 'orange' }} />
              Ga terug
            </Button>
            <Link
              to="/admin/logout"
              style={{ position: 'relative', marginTop: 10, marginLeft: 10, marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)' }}
            >
              <FaSignOutAlt style={{ width: 18, height: 18, marginRight: 5, color: 'orange' }} />
              Uitloggen
            </Link>
            <br />
            <List>
              <Link to="/admin/CreateMountpoint">
                <Button>
                  <FaFileAudio style={{ marginRight: 5, color: 'orange' }} />Stream toevoegen
                </Button>
              </Link>
              <Link to="/admin/users" style={{ color: 'black', textDecoration: 'none' }}>
                <Button>
                  <FaHandshake style={{ marginRight: 5, color: 'orange' }} />Gebruikers
                </Button>
              </Link>
              <Link to="/admin/relays" style={{ color: 'black', textDecoration: 'none' }}>
                <Button>
                  <FaLink style={{ marginRight: 5, color: 'orange' }} />Relays
                </Button>
              </Link>
              <Link to="/admin/flags" style={{ color: 'black', textDecoration: 'none' }}>
                <Button>
                  <FaFlag style={{ marginRight: 5, color: 'orange' }} />Vlaggen
                </Button>
              </Link>
              <Link to="/admin/streamrequests" style={{ color: 'black', textDecoration: 'none' }}>
                <Button>
      <Badge badgeContent={openRequestsData[0]?.empty_taken_by_count} color="primary">
        <FaListAlt style={{ marginRight: 5, color: 'orange' }} />
      </Badge>
      <Typography style={{marginLeft: 15}}>Streamverzoeken</Typography>
    </Button>
              </Link>
              <Alert style={{ width: '100%', marginTop: 10 }} severity="success">
                <strong>Welkom, {username}! Kies hier je mountpoint.</strong>
              </Alert>
            </List>
            <List>
              {/* Display MP values per user */}
              {Object.entries(mpPerUser).map(([user, mpValues]) => (
                      <Accordion key={user}>
                               <AccordionSummary
            expandIcon={<ExpandMoreIcon color="red" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="#428bca" fontWeight="bold">{user ? user : 'Niet toegewezen'}</Typography>
          </AccordionSummary>
                  {mpValues.map((mp, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                      <Link style={{ color: '#428bca', textDecoration: 'none' }} to={`/admin/Editor${mp}`}>
                        <FaPencilAlt style={{ width: 18, height: 18, marginRight: 10, color: 'orange' }} />
                        {mp.replace('/', '')}
                      </Link>
                      {index < mpValues.length - 1 && ' '}
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Accordion>
              ))}
            </List>
          </Card>
        </div>
      );
    }
  };

export default Dashboard;


