import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaPlay } from 'react-icons/fa';
import { FaStop } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { Modal, Box } from '@mui/material';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
import { ToastContainer, toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

    

const AdminRelays = () => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    var myArray = [
      {
          "id": "loading...",
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 71,
          "comments": "Testing",
      },
      {
          "id": 824234111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "test",
      },
      {
          "id": 824235111,
          "project_id": 1457,
          "issue_id": 123420,
          "activity_id": 188,
          "comments": "Test",
      }
  ];
    
  const [relayData, setRelays] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [openRemoveRelay, setOpenRemoveRelay] = React.useState(false);
  const handleOpenRemoveRelay = () => setOpenRemoveRelay(true);
  const handleCloseRemoveRelay = () => setOpenRemoveRelay(false);


  const [openStopRelay, setOpenStopRelay] = React.useState(false);
  const handleOpenStopRelay= () => setOpenStopRelay(true);
  const handleCloseStopRelay = () => setOpenStopRelay(false);

  const [clickedRelay, setClickedRelay] = useState([]);
  const [clickedRelayMP, setClickedRelayMP] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    background: 'white',
    boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)",
    minWidth: 400,
    borderRadius: 5
  };


  async function loginUser(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/relaysAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin;
  }

  const thisrelays = async () => {
    var isAdminString = await adminChecker();
    const token = await loginUser({
     username: username,
     isAdmin: isAdminString
    });
    setRelays(token)
    setIsLoading(false)
  }


   async function passCreateMountpoint(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/createMountpointAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }



   let handleSubmitRemoveRelay = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ relayToDelete: clickedRelay, mpToDelete: clickedRelayMP, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/removeRelayAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het verwijderen van de gebruiker is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          handleCloseRemoveRelay();
          thisrelays();
      } else {
        toast.error('Het verwijderen van de gebruiker is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };



  let handleSubmitStopRelay = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();
    var trueValue = true;
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: clickedRelay, isAdmin: isAdminString, hasElevatedLiquidSoapRightsForMountpoint: trueValue.toString(CryptoJS.enc.Utf8)})
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/stopRelayAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het stoppen van de relay is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          handleCloseStopRelay();
      } else {
        toast.error('Het stoppen van de relay is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };


  let startRelay = async (name) => {
    var isAdminString = await adminChecker();
    var trueValue = "true";
  
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: name, isAdmin: isAdminString, hasElevatedLiquidSoapRightsForMountpoint: isAdminString})
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/startRelayAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het starten van de relay is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          
      } else {
        toast.error('Het starten van de relay is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  let stopRelay = async (name) => {
    var isAdminString = await adminChecker();
    var trueValue = "true";

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: name, isAdmin: isAdminString, hasElevatedLiquidSoapRightsForMountpoint: isAdminString})
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/stopRelayAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het stoppen van de relay is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          
      } else {
        toast.error('Het stoppen van de relay is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
  

    adminChecker();
      thisrelays();
  


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{ padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


  return(
    <div>      
      
      
      <Card style={{minHeight: 200, minWidth: 350, padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Modal
        open={openRemoveRelay}
        onClose={handleCloseRemoveRelay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je relay {clickedRelay} echt verwijderen?</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmitRemoveRelay}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseRemoveRelay}>Sluiten</Button>
        </Box>
        
      </Modal>




      <Modal
        open={openStopRelay}
        onClose={handleCloseStopRelay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je relay {clickedRelay} echt stoppen?</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmitStopRelay}>Bevestigen</Button>
      </center>

       <Button style={{position: 'relative', float: 'right'}} onClick={handleCloseStopRelay}>Sluiten</Button>
        </Box>
        
      </Modal>

      <Button onClick={() => navigate(-1)}><FaBackspace style={{width: 30, height: 30, marginRight: 10, color: 'orange'}}/>Ga terug</Button>
      <Link to="/admin/logout" style={{position: 'relative', marginTop: 10, marginLeft: 10, marginBottom: 10, textDecoration: 'none', color: 'rgb(66, 139, 202)'}}>      <FaSignOutAlt style={{width: 18, height: 18, marginRight: 5, color: 'orange'}}/>Uitloggen</Link>
      <br />
      <List>
      <Link to="/admin/CreateRelay"><Button><FaFileAudio style={{marginRight: 5, color: 'orange'}} />Relay toevoegen</Button></Link>
        <Alert style={{width: '100%', marginTop: 10}} severity="success"><strong>Welkom, {username}! Kies hier je relay.</strong></Alert>
        </List>
      <List>
      {relayData.map((relay, index) => (
      <ListItem divider={index < relayData.length - 1} key={index}>
      <Button onClick={() => {
          startRelay(relay.name);
        }} ><FaPlay style={{width: 18, height: 18, color: 'orange'}}/></Button>
      <Button onClick={() => {
          setClickedRelay(relay.name);
          handleOpenStopRelay(relay.name);
        }}><FaStop style={{width: 18, height: 18, color: 'orange'}}/></Button>
      <Button onClick={() => {
          setClickedRelay(relay.name);
          setClickedRelayMP(relay.destination);
          handleOpenRemoveRelay();
        }}><FaTrash style={{width: 18, height: 18, color: 'orange'}}/></Button>
      <Link style={{color: '#428bca', textDecoration: 'none'}} to={`/admin/Editor${relay.destination}`}><FaPencilAlt style={{width: 18, height: 18, marginRight: 10, color: 'orange'}}/>{relay.name}</Link>

      </ListItem>
         ))}
     </List>
      </Card>
    </div>
  );
 }
}

export default AdminRelays;


