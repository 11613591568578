import React, {useState, useEffect} from 'react';
import { Card } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Divider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { FaPencilAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaBackspace } from 'react-icons/fa';
import { FaFileAudio } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Lottie from "lottie-react";
import Loading from "./98635-loading.json";
var CryptoJS = require("crypto-js");

    

const AdminRemoveUser = props => {
    const usernameString = localStorage.getItem('username');
    const username = JSON.parse(usernameString);
    const navigate = useNavigate();
    
    const { clickedUser } = useParams();
  const [usersData, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const [changeUsername, setChangeUsername] = useState([]);
  const [changePassword, setChangePassword] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


   async function checkIfAdmin(credentials) {
    return fetch('https://catalina.xseu.net/beheer-pz/checkAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }


   const adminChecker = async () => {
    const adminInfo = await checkIfAdmin({
     username: username
    });
    var decrypted = CryptoJS.AES.decrypt(adminInfo.isAdmin, "4CHUNFCHJ99XJ!");
    var originaltext = decrypted.toString(CryptoJS.enc.Utf8);

    setIsAdmin(originaltext)
    return adminInfo.isAdmin
  }
  

  let handleSubmit = async (e) => {
    e.preventDefault();
    var isAdminString = await adminChecker();

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameToDelete: props.logged_user, isAdmin: isAdminString })
    };
    let res = await fetch('https://catalina.xseu.net/beheer-pz/removeUserAdmin', requestOptions)
        .then(response => response.json())
      if (res.message === "Bijwerken geslaagd") {
        toast.success('Het verwijderen van de gebruiker is geslaagd.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          
      } else {
        toast.error('Het verwijderen van de gebruiker is mislukt.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {

adminChecker();
setIsLoading(false)


 }, [])

 if (isLoading == true) {
  return (
    <div>
    <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>
    <center>
    <Lottie animationData={Loading} style={{width: 50, height: 50}} />
    <Typography><strong>Laden...</strong></Typography>
    </center>
    </Card>
    </div>
  )
} else {


 if (isAdmin != "true") {
    return (
      <div>
      <Card style={{padding: 10, boxShadow: "0 1px 22px 4px rgba(0,0,0,.07)" }}>

      <Alert severity="error"><strong>Sorry, {username}! Je hebt geen rechten om dat te doen</strong></Alert>
      </Card>
      </div>
    )
  }


  return(
    <div>
      <center>
        <Alert severity='warning' style={{fontWeight: 'bold'}}>Wil je {props.logged_user} echt verwijderen?</Alert>
        <br />
        <Alert severity='info'>Ververs de pagina om de wijzigingen te zien.</Alert>
        <br />
        <Button variant="contained" onClick={handleSubmit}>Bevestigen</Button>
      </center>
    </div>
  );
 }
}

export default AdminRemoveUser;


